import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
    AddDeviceRequest,
    DeviceConsumption,
    IDeviceDetail,
    DeviceSummary,
    IDevice,
    ProductType,
    UpdateDeviceRequest,
    IRemainingEnergy
} from 'app/models/devices.type';
import { BehaviorSubject, catchError, finalize, map, Observable, of, take, tap, throwError } from 'rxjs';
import { GroupService } from './group.service';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { DeviceScheduleService } from './device-schedule.service';
import { DeviceObjectService } from './device-object.service';
import { BaseEntityFilter, BaseParentChildEntityService, EntityTransformFn, ParentChildCrudEndpoints } from '@gci/components/base-page/base-parent-child.service';
import { environment } from 'environments/environment';
import { PaginationMetaData } from 'app/models/event.type';
import { get } from 'lodash';

interface IDeviceFilter extends BaseEntityFilter {
    // Add member-specific filter properties if needed
}

interface PaginatedResponse<T> {
    message: {
        status: string;
        status_message: string;
        data: T[];
        pagination: {
            total_records: number;
            page_size: number;
            current_page: number;
            total_pages: number;
            has_next_page: boolean;
            has_previous_page: boolean;
        };
    };
}


@Injectable({ providedIn: 'root' })
export class DevicesService extends BaseParentChildEntityService<IDevice, IDeviceFilter, string> {
    protected override parentIdField: keyof IDevice = 'group_id';

    // -----------------------------------------------------------------------------------------------------
    // @ Properties
    // -----------------------------------------------------------------------------------------------------
    private _httpClient = inject(HttpClient);
    private _groupService = inject(GroupService);

    // Custom endpoints for devices
    protected override endpoints: ParentChildCrudEndpoints = {
        list: (groupId: string) => `device-service/${groupId}/device`,
        create: (groupId: string) => `device-service/${groupId}/device`,
        search: () => `device-service/device`,
        update: (id: string, groupId?: string) => `device-service/${groupId}/device/${id}`,
        delete: (id: string, groupId?: string) => `group-service/${groupId}/device-group/${id}`,
        get_device_by_group: (groupId: string) => `device-service/${groupId}/device`,
        device_energy_consumption: () => `dw-service/dw`,
        get_remaining_energy: () => `ts-fetch/remaining-energy`,
    };
    private _paginationCount = new Map<string, number>();
    private _activeCacheKey = new BehaviorSubject<string>('');
    private default_page_size = 24;

    // New BehaviorSubject to store all device details
    private _allDeviceDetails = new BehaviorSubject<Map<string, Map<string, IDeviceDetail>>>(new Map());
    // Additional BehaviorSubjects for device-specific data
    private _currentDevice = new BehaviorSubject<IDeviceDetail | null>(null);

    // New BehaviorSubject to store all group summaries
    private _allGroupSummaries = new BehaviorSubject<Map<string, DeviceSummary>>(new Map());
    private _summary = new BehaviorSubject<DeviceSummary>({
        count_devices: 0,
        inactive: 0,
        active: 0,
        online: 0,
        offline: 0,
        device_unique_events: 0,
        device_events: 0,
        group_members: 0
    });

    // New BehaviorSubject to store consumption data for all groups
    private _allGroupConsumptions = new BehaviorSubject<Map<string, Map<string, DeviceConsumption[]>>>(new Map());
    private _highestConsumption = new BehaviorSubject<DeviceConsumption[]>([]);

    // -----------------------------------------------------------------------------------------------------
    // @ Constructor
    // -----------------------------------------------------------------------------------------------------
    constructor(
        httpClient: HttpClient,
        private _scheduleService: DeviceScheduleService,
        private _deviceObjectService: DeviceObjectService,
    ) {
        super(httpClient);
        // Set custom cache duration if needed
        this.setCacheConfig({ cacheDuration: 5 * 60 * 1000 }); // 5 minutes
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Add activeGroupDevices$ as an alias to entities$
     */
    get activeGroupDevices$(): Observable<IDevice[]> {
        return this.entities$;
    }

    get currentDevice$(): Observable<IDeviceDetail | null> {
        return this._currentDevice.asObservable();
    }

    /**
     * New accessor for all device details
     */
    get allDeviceDetails$(): Observable<Map<string, Map<string, IDeviceDetail>>> {
        return this._allDeviceDetails.asObservable();
    }

    /**
     * Helper method to get device detail for a specific group and device
     * @param groupId 
     * @param deviceId 
     * @returns 
     */
    getDeviceDetailFromCache(groupId: string, deviceId: string): IDeviceDetail | undefined {
        return this._allDeviceDetails.value.get(groupId)?.get(deviceId);
    }

    get summary$(): Observable<DeviceSummary> {
        return this._summary.asObservable();
    }

    /**
     * New getter for all group summaries
     */
    get allGroupSummaries$(): Observable<Map<string, DeviceSummary>> {
        return this._allGroupSummaries.asObservable();
    }

    /**
     * Helper method to get summary for a specific group
     * @param groupId 
     * @returns 
     */
    getSummaryForGroup(groupId: string): DeviceSummary | undefined {
        return this._allGroupSummaries.value.get(groupId);
    }

    get highestConsumption$(): Observable<DeviceConsumption[]> {
        return this._highestConsumption.asObservable();
    }

    /**
     * New getter for all group consumptions
     */
    get allGroupConsumptions$(): Observable<Map<string, Map<string, DeviceConsumption[]>>> {
        return this._allGroupConsumptions.asObservable();
    }

    /**
     * Helper method to get consumption for a specific group and unit
     * @param groupId 
     * @param unit 
     * @returns 
     */
    getConsumptionForGroup(groupId: string, unit: string): DeviceConsumption[] | undefined {
        return this._allGroupConsumptions.value.get(groupId)?.get(unit);
    }

    set currentDevice(device: IDeviceDetail) {
        this._currentDevice.next(device);
        // Update the cached device details
        if (device && device.group_id) {
            const updatedDetails = new Map(this._allDeviceDetails.value);
            if (!updatedDetails.has(device.group_id)) {
                updatedDetails.set(device.group_id, new Map());
            }
            updatedDetails.get(device.group_id)?.set(device.id, device);
            this._allDeviceDetails.next(updatedDetails);

        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ CRUD Device List
    // -----------------------------------------------------------------------------------------------------

    /**
     * Override getList to add device-specific behavior
     * @param groupId 
     * @param queryParams 
     * @param forceRefresh 
     * @returns 
     */
    override getList(groupId: string, queryParams?: Record<string, string>, forceRefresh = false): Observable<IDevice[]> {

        // Create a cachekey that contain the groupId and the queryparams as a unique key to store the data in the cache
        const cacheKey = this.generateCacheKey(groupId, queryParams);

        // Save the query params value of page and page_size
        const page = queryParams ? Number(queryParams['page']) : 1;
        const page_size = queryParams ? Number(queryParams['limit']) : this.default_page_size;

        const allEntities = this._allEntities.getValue();
        const activeCacheKey = this._activeCacheKey.getValue();

        if (
            !forceRefresh &&
            this.isCacheValid(cacheKey) &&
            this.isAmountOfCacheItemsExist(page, page_size, cacheKey)
        ) {
            let total_records = this._paginationCount.get(cacheKey);
            if (total_records) {
                let total_pages = Math.ceil(total_records / page_size);
                let current_page = page ?? 1;
                let pagination: PaginationMetaData = {
                    total_records,
                    page_size,
                    current_page,
                    total_pages,
                    has_next_page: current_page < total_pages,
                    has_previous_page: current_page > 1,
                }
                this._pagination.next(pagination);
            }
            let data = this.sliceCacheItems(cacheKey, page, page_size);
            this._entities.next(data);
            this._activeCacheKey.next(cacheKey);
            return of(data);
        }

        this._loading.next(true);

        // When the CacheKey is changed, clear any invalid cache
        if (cacheKey !== activeCacheKey) {
            this.clearRelatedCache('', 'clearInvalid');
        }

        const endpoint = this.endpoints.list ? this.endpoints.list(groupId) : '';
        const url = `${this.baseUrl}${endpoint}${this.buildQueryString(queryParams)}`;

        return this.httpClient.get<PaginatedResponse<IDevice>>(url).pipe(
            map(response => {
                if (response.message && !Array.isArray(response.message)) {
                    if (!response.message.data || !Array.isArray(response.message.data)) {
                        throw new Error(`Invalid paginated response format for ${url}`);
                    }
                    if (response.message.pagination) {
                        let pagination = response.message.pagination;
                        this._paginationCount.set(cacheKey, pagination.total_records);
                        this._pagination.next(pagination);
                    }
                    return response.message.data;
                }
                if (!Array.isArray(response.message)) {
                    throw new Error(`Invalid response format for ${url}`);
                }
                return response.message;
            }),
            tap(response => {
                if (!Array.isArray(response)) {
                    throw new Error(`Response message is not an array ` + url);
                }

                let enrichedResponse = response.map(device => ({
                    ...device,
                    [this.parentIdField]: device[this.parentIdField] || groupId
                }));

                if (!allEntities[cacheKey]) {
                    allEntities[cacheKey] = {
                        data: enrichedResponse,
                        timestamp: Date.now()
                    };
                } else {
                    enrichedResponse = this.mergeArrayOfObject(allEntities[cacheKey].data, enrichedResponse, 'id');
                    allEntities[cacheKey] = {
                        data: enrichedResponse,
                        timestamp: allEntities[cacheKey].timestamp
                    }
                }

                this._entities.next(enrichedResponse);
                this._allEntities.next(allEntities);
                this._activeCacheKey.next(cacheKey);
                this._loading.next(false);
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('getList', error);
            })
        )
    }

    /**
     * Add getDeviceList as an alias for clarity
     * @param groupId 
     * @param forceRefresh 
     * @returns 
     */
    getDeviceList(groupId: string,
        page?: number, limit?: number,
        sort?: string, sortBy?: string,
        search?: string, active?: boolean,
        product_id?: string,
        status?: string,
        date?: string,
        forceRefresh = false): Observable<IDevice[]> {
        let queryParams: Record<string, string> = {
            page: page?.toString() ?? '1',
            limit: limit ? limit?.toString() : this.default_page_size.toString(),
            sort: sort ?? 'desc',
            sortby: sortBy ?? 'created_at',
            search: search ?? '',
            active: active !== undefined ? active ? 'true' : 'false' : '',
            product_id: product_id ?? '',
            status: status ?? '',
        }

        if (date) {
            queryParams['date'] = date;
        }

        return this.getList(groupId, queryParams, forceRefresh);
    }

    assignDevice(body: AddDeviceRequest, groupId: string): Observable<IDevice> {

        // Start the loading
        this._loading.next(true);

        const endpoint = `group-service/${groupId}/device-group/${body.device_id}`;
        const url = `${this.baseUrl}${endpoint}`;

        const enrichedBody = {
            ...body,
            [this.parentIdField]: groupId
        }

        return this.httpClient.post<{ message: { data: IDevice } }>(url, enrichedBody).pipe(
            map(response => response.message.data),
            tap(response => {
                const defaultKey = this.getDefaultCacheKey();
                this.clearRelatedCache(defaultKey, 'clearAll');

                const allEntities = this._allEntities.getValue();
                const newEntities = [response];
                const updatedData = allEntities[defaultKey] ? [...newEntities, ...allEntities[defaultKey].data] : newEntities;
                const timestamp = allEntities[defaultKey] ? allEntities[defaultKey].timestamp : Date.now();

                this.updateCache(defaultKey, updatedData, timestamp);

                this.managePagination(defaultKey, 'create');

                this._loading.next(false);
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('create', error);
            })
        )
    }

    override update(id: string, body: Partial<IDevice>, parentId: string, queryParams?: Record<string, string>, transformFn?: EntityTransformFn<IDevice> | undefined): Observable<IDevice> {
        this._loading.next(true);
        const cacheKey = this._activeCacheKey.getValue();
        const endpoint = this.endpoints.update ? this.endpoints.update(id, parentId) : '';
        const url = `${this.baseUrl}${endpoint}${this.buildQueryString(queryParams)}`;

        const enrichedBody = {
            ...body,
            [this.parentIdField]: parentId
        };

        return this.httpClient.put<{ message: {data: IDevice} }>(url, enrichedBody).pipe(
            map(res => res.message.data),
            tap(res => {
                this.clearRelatedCache(cacheKey, 'clearAll');

                const allEntities = this._allEntities.getValue();
                const pagination = this._pagination.getValue();

                if (allEntities[cacheKey]) {
                    const entityIndex = allEntities[cacheKey].data.findIndex(e => e.id === id);
                    if (entityIndex !== -1) {
                        const updatedEntity = transformFn
                            ? transformFn(allEntities[cacheKey].data[entityIndex], res)
                            : res;

                        allEntities[cacheKey].data[entityIndex] = updatedEntity;
                        if (pagination) {
                            let entity = this.sliceCacheItems(cacheKey, pagination.current_page, pagination.page_size);
                            this._entities.next(entity);
                        }
                        this._allEntities.next(allEntities);
                    }
                }
                this._loading.next(false);
            }),
            catchError(error => {
                console.error("Error Updating Device: ", error);
                this._loading.next(false);
                return this.handleOperationError('update', error.message);
            })
        )

    }

    /**
     * Override update method for devices
     * @param body 
     * @param id 
     * @returns 
     */
    updateDevice(body: UpdateDeviceRequest, id: string): Observable<IDevice> {
        const groupId = body.group_id ? body.group_id : '';

        return this.update(
            id,
            body,
            groupId,
            undefined,
            (oldDevice, newDevice) => ({
                ...newDevice,
                group_id: groupId
            }))
            .pipe(
                tap(response => {
                    // Update current device if it's the one being edited
                    if (this._currentDevice.value?.id === response.id) {
                        const deviceDetail: IDeviceDetail = {
                            ...response,
                            product_id: '',
                            active: false,
                            webhook_id: '',
                            location_id: null,
                            alert_rules: [],
                            device_object: [],
                            event: [],
                            schedule: []
                        };
                        this._currentDevice.next(deviceDetail);
                    }
                }),
                catchError(error => {
                    console.error('Error updating device:', error);
                    return throwError(() => new Error(error.message));
                })
            );
    }

    override delete(id: string, parentId: string, body?: any, queryParams?: Record<string, string>): Observable<IDevice> {
        this._loading.next(true);

        const cacheKey = this._activeCacheKey.getValue();
        const endpoint = this.endpoints.delete ? this.endpoints.delete(id, parentId) : '';
        const url = `${this.baseUrl}${endpoint}${this.buildQueryString(queryParams)}`;
        const options = body ? { body } : undefined;

        return this.httpClient.delete<{ message: IDevice }>(url, options).pipe(
            map(response => response.message),
            tap(() => {
                this.clearRelatedCache(cacheKey, 'clearAll');

                const allEntities = this._allEntities.getValue();
                const updatedData = allEntities[cacheKey]?.data.filter(item => item.id !== id);

                this.updateCache(cacheKey, updatedData, allEntities[cacheKey]?.timestamp);

                this.managePagination(cacheKey, 'delete');

                this._loading.next(false);
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('delete', error);
            })
        )

    }

    /**
     * Override delete method for devices
     * @param id 
     * @param groupId 
     * @returns 
     */
    removeDevice(id: string, groupId: string): Observable<IDevice> {
        console.log(id, groupId);
        return this.delete(id, groupId).pipe(
            tap(response => {
                console.log(response)
                // Clear current device if it's the one being deleted
                if (this._currentDevice.value?.id === id) {
                    this._currentDevice.next(null);
                }

                // Update summary after device removal
                this.getDeviceSummary(groupId, true).subscribe();
            }),
            catchError(error => {
                console.error('Error removing device:', error);
                return throwError(() => new Error(error.message));
            })
        );
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Other Methods
    // -----------------------------------------------------------------------------------------------------


    searchDevice(filter = '', page = '1', limit = '9', tenant_id?: string) {
        const queryParams: Record<string, string | undefined> = { search: filter, page, limit, sort: 'desc', sortby: 'created_at', tenant_id: tenant_id };

        const endpoint = this.endpoints.search ? this.endpoints.search() : '';
        const url = `${this.baseUrl}${endpoint}${this.buildQueryString(queryParams)}`;
        this._loading.next(true);


        return this.httpClient.get<PaginatedResponse<IDevice[]>>(url).pipe(
            map(response => {
                return response.message.data;
            }),
            tap(devices => {
                return devices;
            }),
            catchError(error => {
                console.error(error);
                return this.handleOperationError('getList', error);
            }),
            finalize(() => {
                this._loading.next(false);
            })
        )
    }

    /**
     * Force refresh method for clarity
     * @param groupId 
     * @returns 
     */
    forceRefreshDevices(groupId: string): Observable<IDevice[]> {
        return this.getDeviceList(groupId, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
    }

    /**
     * Method to get the devices summary data
     * @param groupId 
     * @param forceRefresh 
     * @returns 
     */
    getDeviceSummary(groupId: string, forceRefresh = false, date?: string): Observable<DeviceSummary> {
        const currentSummaries = this._allGroupSummaries.value;
        const existingSummary = currentSummaries.get(groupId);

        if (!forceRefresh && this.isCacheValid(groupId) && existingSummary) {
            // Update the last accessed summary for backward compatibility
            this._summary.next(existingSummary);
            return this._summary.asObservable();
        }

        return this._httpClient.get<{ message: DeviceSummary }>(`${this.baseUrl}group-service/group-summary/${groupId}${date ? '?date=' + date : ''}`).pipe(
            map(res => res.message),
            tap(summary => {
                this._summary.next(summary);
                const updatedSummaries = new Map(this._allGroupSummaries.value);
                updatedSummaries.set(groupId, summary);
                this._allGroupSummaries.next(updatedSummaries);
            }),
            catchError(err => {
                console.error('Error fetching device summary: ', err);
                return throwError(() => new Error('Failed to fetch device summary: ' + err.message));
            }),
        )
    };

    // forceRefreshConsumption(groupId: string, unit: string = 'current'): Observable<DeviceConsumption[]> {
    //     return this.getDeviceHighestConsumption(groupId, unit, true);
    // }

    // /**
    //  * Highest Consumption Methods
    //  * @param groupId 
    //  * @param unit 
    //  * @param forceRefresh 
    //  * @returns 
    //  */
    // getDeviceHighestConsumption(groupId: string, unit: string = 'current', forceRefresh = false): Observable<DeviceConsumption[]> {
    //     const currentConsumptions = this._allGroupConsumptions.value;
    //     const groupConsumptions = currentConsumptions.get(groupId);
    //     const existingConsumption = groupConsumptions?.get(unit);

    //     if (!forceRefresh && this.isCacheValid(groupId) && existingConsumption) {
    //         // Update the last accessed consumption for backward compatibility
    //         this._highestConsumption.next(existingConsumption);
    //         return this._highestConsumption.asObservable();
    //     }

    //     return this.httpClient
    //         .get<{ message: DeviceConsumption[] }>(`${this.baseUrl}device-service/${groupId}/highest-consumption?key=${unit}`)
    //         .pipe(
    //             map(response => response.message),
    //             tap(consumption => {
    //                 this._highestConsumption.next(consumption);

    //                 // Update the cached consumption for this group and unit
    //                 const updatedConsumptions = new Map(this._allGroupConsumptions.value);
    //                 if (!updatedConsumptions.has(groupId)) {
    //                     updatedConsumptions.set(groupId, new Map());
    //                 }
    //                 updatedConsumptions.get(groupId)?.set(unit, consumption);
    //                 this._allGroupConsumptions.next(updatedConsumptions);
    //             }),
    //             catchError(error => {
    //                 console.error('Error fetching consumption:', error);
    //                 return throwError(() => new Error('Failed to fetch device consumption: ' + error.message));
    //             })
    //         );
    // }

    /**
     * Device Detail Methods
     * @param groupId 
     * @param deviceId 
     * @param forceRefresh 
     * @returns 
     */
    getDeviceDetail(groupId: string, deviceId: string, forceRefresh = false): Observable<IDeviceDetail | null> {
        const cachedDevice = this.getDeviceDetailFromCache(groupId, deviceId);

        if (!forceRefresh && this.isCacheValid(groupId) && cachedDevice) {
            // Update current device for backward compatibility
            this._currentDevice.next(cachedDevice);
            return this._currentDevice.asObservable();
        }

        return this.httpClient
            .get<{ message: { data: IDeviceDetail } }>(`${this.baseUrl}device-service/${groupId}/device/${deviceId}`)
            .pipe(
                map(response => response.message.data),
                tap(device => {
                    // TODO: temporarily removed the device_object and schedule properties from the response
                    // because the device_object and schedule properties will be download separately
                    device.device_object = [];
                    device.schedule = [];

                    // Update both current device and cached details
                    this._currentDevice.next(device);

                    const updatedDetails = new Map(this._allDeviceDetails.value);
                    if (!updatedDetails.has(groupId)) {
                        updatedDetails.set(groupId, new Map());
                    }
                    updatedDetails.get(groupId)?.set(deviceId, device);
                    this._allDeviceDetails.next(updatedDetails);
                }),
                catchError(error => {
                    console.error('Error fetching device detail:', error);
                    return throwError(() => new Error(error.error.message));
                })
            );
    }

    updateDeviceNotificationReadStatus(notifId: string): Observable<Notification> {

        const body = {
            read: true
        }

        return this._httpClient.put<{ message: Notification }>(`${this.baseUrl}notification/history/${notifId}`, body).pipe(
            map(res => res.message),
            tap(res => {
                this._logger.log("Update Notification Read Status: ", res);
            })
        )
    }

    /**
     * Override create method for devices
     * @param body 
     * @returns 
     */
    addDevice(body: AddDeviceRequest): Observable<IDevice> {
        const groupId = body.group_id;
        this._groupService.activeGroup = groupId;

        return this.create(body, groupId).pipe(
            tap(() => {
                this.clearRelatedCache(this._activeCacheKey.getValue(), 'create')
                // Update device summary after adding device
                this.getDeviceSummary(groupId, true).subscribe();
            }),
            catchError(error => {
                console.error('Error adding device:', error);
                return throwError(() => new Error(error.error.message));
            })
        );
    }

    /**
     * Turn on or off the device's breaker
     * @param status 
     * @returns 
     */
    deviceBreakerControl(status: 'on' | 'off', deviceId: string): Observable<string> {

        // Don't use the deviceId from the database for now
        // Use this placeholder for the deviceId instead for now
        if (!environment.deviceIdCanControl.includes(deviceId) && !environment.deviceIdCanControl.includes('all')) {
            return throwError(() => new Error(`This Device Shouldn't Control`))
        }

        return this._httpClient.post<{ message: string }>(`${this.baseUrl}device-service/breaker/${status}/${deviceId}`, {}).pipe(
            map(res => res.message),
            catchError(error => {
                error('Error occurred: ', error);
                return throwError(() => new Error(error.error.message));
            })
        )
    }

    getDeviceEnergyConsumption(device_id: string, group_id: string, date: string){

        const queryParams: Record<string, string> = {
            date: date,
            search: device_id,
            sort: 'desc',
            sortby: 'energy_consumption'
        }

        const url = `${this.baseUrl}${this.endpoints['get_device_by_group'](group_id)}${this.buildQueryString(queryParams)}`;

        return this.httpClient.get<{message: any}>(url).pipe(
            map(res => res.message),
            catchError(err => {
                console.error(err);
                return this.handleOperationError("getDeviceEnergyConsumption", err);
            })
        )
    }

    getDeviceRemainingEnergy(device_id: string){

        const queryParams: Record<string, string> = {
            device_id
        }

        const url = `${this.baseUrl}${this.endpoints['get_remaining_energy']()}${this.buildQueryString(queryParams)}`;
            
        return this.httpClient.get<{message: {data: IRemainingEnergy}}>(url).pipe(
            map(res => res.message),
            catchError(err => {
                console.error(err);
                return this.handleOperationError("getDeviceRemainingEnergy", err);
            })
        )

    }

    override clearCache(): void {
        super.clearCache();
        this._summary.next({
            count_devices: 0,
            inactive: 0,
            active: 0,
            online: 0,
            offline: 0,
            device_unique_events: 0,
            device_events: 0,
            group_members: 0
        });
        this._allGroupSummaries.next(new Map());
        this._allGroupConsumptions.next(new Map());
        this._highestConsumption.next([]);
        this._currentDevice.next(null);
        this._allDeviceDetails.next(new Map());
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Helpers
    // -----------------------------------------------------------------------------------------------------

    protected override isCacheValid(cacheKey: string): boolean {
        const allEntities = this._allEntities.getValue();
        if (!allEntities[cacheKey]) return false;
        if (this._paginationCount.get(cacheKey) === undefined) return false;

        const currentTime = Date.now();
        const cacheAge = currentTime - allEntities[cacheKey].timestamp;
        return cacheAge < this.cacheConfig.cacheDuration;
    }

    protected generateCacheKey(parentId: string, params?: Record<string, string>): string {
        let paginationKey = parentId;
        if (params) {
            let queryParams = { ...params };
            delete queryParams['page'];
            let strParams = JSON.stringify(queryParams);
            paginationKey += `/${strParams}`;
        }
        return paginationKey;
    }

    private getDefaultCacheKey() {
        const cacheKey = this._activeCacheKey.getValue();
        const { parentId } = this.decipherCacheKey(cacheKey);
        const queryParams: Record<string, string> = {
            page: '1',
            limit: this.default_page_size.toString(),
            sort: 'desc',
            sortby: 'created_at',
            search: '',
            active: '',
            product_id: '',
            status: '',
        }

        return this.generateCacheKey(parentId, queryParams);
    }

    private decipherCacheKey(cacheKey: string): { parentId: string, params: Record<string, string> } {
        let splitKey = cacheKey.split('/');
        let parentId = splitKey.shift() as string;
        let params = splitKey.length > 0 ? JSON.parse(splitKey.join('')) : {};
        return { parentId, params };
    }

    private updateCache(cacheKey: string, data: IDevice[], timestamp: number): void {
        const allEntities = this._allEntities.getValue();
        allEntities[cacheKey] = { data, timestamp };
        this._allEntities.next(allEntities);
    }

    private mergeArrayOfObject(array1: IDevice[], array2: IDevice[], keyword: keyof IDevice): IDevice[] {
        return [...new Map([...array1, ...array2].map(item => [item[keyword], item])).values()];
    }

    private isAmountOfCacheItemsExist(page: number, page_size: number, cacheKey: string): boolean {
        if (this._allEntities.getValue()[cacheKey]) {
            const cacheLength = this._allEntities.getValue()[cacheKey].data.length;
            const total_records = this._paginationCount.get(cacheKey);
            return cacheLength >= page * page_size || cacheLength === total_records;
        }
        return false;
    }

    private sliceCacheItems(cacheKey: string, page: number, page_size: number): IDevice[] {
        let allEntities = this._allEntities.getValue();
        return allEntities[cacheKey].data.slice(0, page * page_size);
    }

    /**
     * Clear the related cache of parentId other than the main one
     * @param parentId 
     * @param cacheKey 
     */
    private clearRelatedCache(cacheKey: string, option: 'clearAll' | 'clearInvalid' | 'update' | 'create') {
        const parentId = cacheKey.split('/')[0];
        const allEntities = this._allEntities.getValue();
        const paginationCount = this._paginationCount;

        // Clear all cache with related parentId
        if (option === 'update' || option === 'create') {
            for (let key in allEntities) {
                if (key.startsWith(parentId)) {
                    delete allEntities[key];
                    paginationCount.delete(key);
                }
            }
        }

        // Deleting all invalid caches except for the inputted cache
        if (option === 'clearInvalid') {
            for (let key in allEntities) {
                if (!this.isCacheValid(key) && key !== cacheKey) {
                    delete allEntities[key];
                    paginationCount.delete(key);
                }
            }
        }

        // Deleting all caches with the same parentId keyword other than the inputted cache 
        if (option === 'clearAll') {
            for (let key in allEntities) {
                if (key.startsWith(parentId) && key !== cacheKey) {
                    delete allEntities[key];
                    paginationCount.delete(key);
                }
            }
        }

        // Update the schedule and pagination cache
        this._allEntities.next(allEntities);
    }

    private managePagination(cacheKey: string, method: 'create' | 'delete') {
        let { parentId, params } = this.decipherCacheKey(cacheKey);
        let paginationCount = this._paginationCount;
        let pagination = this._pagination.getValue();
        let activeCacheKey = this._activeCacheKey.getValue();

        if (method === 'delete' && paginationCount.get(activeCacheKey) && pagination) {
            let { parentId, params } = this.decipherCacheKey(cacheKey);
            let search = params['search'];
            let sort = params['sort'];
            let sortby = params['sortby'];
            let active = params['active'].toLowerCase() === 'true' ? true : params['active'].toLowerCase() === 'false' ? false : undefined;
            let product_id = params['product_id'];
            let status = params['status'];

            pagination.total_records--;
            pagination.total_pages = Math.ceil(pagination.total_records / pagination.page_size);
            let entities = this._entities.getValue();
            if (entities.length - (pagination.current_page - 1) * this.default_page_size === 1) {
                pagination.current_page--;
            };
            pagination.has_previous_page = pagination.current_page > 1;
            pagination.has_next_page = pagination.current_page < pagination.total_pages;

            this._pagination.next(pagination);
            this._paginationCount.set(cacheKey, pagination.total_records);

            return this.getDeviceList(parentId, pagination.current_page, pagination.page_size, sort, sortby, search, active ? active : undefined, product_id, status ? status : undefined).pipe(take(1)).subscribe();
        }

        if (method === 'create' && pagination && paginationCount.get(cacheKey)) {
            pagination.total_records++;
            pagination.total_pages = Math.ceil(pagination.total_records / pagination.page_size);
            pagination.current_page;
            pagination.has_previous_page = pagination.current_page > 1;
            pagination.has_next_page = pagination.current_page < pagination.total_pages;

            this._pagination.next(pagination);
            this._paginationCount.set(cacheKey, pagination.total_records);

            return this.getDeviceList(parentId, pagination.current_page, pagination.page_size).pipe(take(1)).subscribe();
        }
        return this.getDeviceList(parentId, 1, this.default_page_size).pipe(take(1)).subscribe();
    }
}