import { PaginationMetaData } from "app/models/event.type";

export interface KeyTable {
    [key: string]: string[]
}

export const TableHeader: KeyTable = {
    tenant: ['Tenant name', 'Groups', 'Members', 'Devices'],
    groups: ['Name', 'Tenant', 'Devices'],
    users: ['Name', 'Email', 'Phone', 'Tenant', 'Join At'],
    devices: ['SN', 'Name', 'Registered', 'Warranty', 'Status'],
    'income': ['ID Transaction', 'Tenant', 'Name', 'Cost'],
    'devices-offline': ['SN', 'Tenant', 'Name'],
}

export const TableKey: KeyTable = {
    tenant: ['name', 'group', 'members', 'devices'],
    groups: ['name', 'tenant', 'devices'],
    users: ['name', 'email', 'phone_number', 'tenant', 'join_at'],
    devices: ['id', 'name', 'registered_at', 'warranty_until', 'status_name'],
    'income': ['id', 'tenant', 'name', 'cost'],
    'devices-offline': ['id', 'tenant', 'name']
}

export const TableAdminHeader: KeyTable = {
    groups: ['Name', 'Solution', 'Notes'],
    devices: ['SN', 'Name', 'Registered', 'Warranty', 'Status', 'Description', 'Product'],
    users: ['Name', 'Email', 'Phone', 'Join At', 'Last Online'],
    'ticket': ['SN', 'Name', 'Problem', 'Created At'],
    'income': ['SN', 'No Meter', 'Total Purchase', 'Total kWh'],
    energy: ['SN', 'No Meter', 'Total kWh', 'Estimated costs'],
    'detail_transaction': ['TOKEN', 'Status', 'Purchase Date', 'Cost', 'Total kWh', 'Payment Type'],
    'detail_energy': ['Date', 'Total Consuption', 'Estimated costs'],
    'devices-trouble': ['SN', 'Name', 'Status', 'Description']
}

export const TableAdminKey: KeyTable = {
    groups: ['alias', 'solution_name', 'notes'],
    devices: ['id', 'name', 'created_at_date', 'warranty', 'status_converter', 'description', 'product_name'],
    users: ['name', 'email', 'phone_number', 'created_at_date', 'last_online_date'],
    'ticket': ['device_id', 'name', 'problem', 'created_at'],
    'income': ['id', 'meter_id', 'total_purchase', 'energy_consumption'],
    energy: ['id', 'meter_id', 'energy_consumption', 'estimation_cost'],
    'detail_transaction': ['token', 'status',  'date', 'cost', 'energy', 'payment_method'],
    'detail_energy': ['date', 'energy_consumption', 'estimation_cost'],
    'devices-trouble': ['id', 'name', 'status_converter', 'description']
}

export const PAGESIZE = '10';

export const PaginationDefault: PaginationMetaData = {
    total_records: 10,
    page_size: 10,
    current_page: 1,
    total_pages: 2,
    has_next_page: true,
    has_previous_page: false
}

export interface IEnergy {
    id: string; date: string; energy: number; device_id: string; no_meter: string;
}

export interface ITransaction {
    id: string; date: string; cost: number; energy: number; device_id: string; payment_type: string;
}