import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseEntityFilter, BaseParentChildEntityService, EntityTransformFn, ParentChildCrudEndpoints } from '@gci/components/base-page/base-parent-child.service';
import { PaginationMetaData } from 'app/models/event.type';
import { ISchedule, ScheduleForm } from 'app/models/schedule.type';
import { BehaviorSubject, catchError, map, Observable, of, take, tap } from 'rxjs';

interface ScheduleFilter extends BaseEntityFilter {
    // Add any specific filter properties for roles if needed
}

interface EntityCache<T> {
    [key: string]: {
        [page: number]: {
            data: T[];
            timestamp: number;
        }
    };
}

@Injectable({
    providedIn: 'root'
})
export class DeviceScheduleService extends BaseParentChildEntityService<ISchedule, ScheduleFilter, string> {

    // -----------------------------------------------------------------------------------------------------
    // @ Properties
    // -----------------------------------------------------------------------------------------------------
    protected override parentIdField: keyof ISchedule = 'device_id';
    protected override endpoints: ParentChildCrudEndpoints = {
        list: (deviceId: string) => `device-service/${deviceId}/schedule`,
        create: (deviceId: string) => `device-service/${deviceId}/schedule`,
        update: (scheduleId: string, deviceId?: string) => `device-service/${deviceId}/schedule/${scheduleId}`,
        delete: (scheduleId: string, deviceId?: string) => `device-service/${deviceId}/schedule/${scheduleId}`
    };

    // protected override _allEntities: BehaviorSubject<EntityCache<ISchedule>> = new BehaviorSubject<EntityCache<ISchedule>>({});
    protected _allEntities2: BehaviorSubject<EntityCache<ISchedule>> = new BehaviorSubject<EntityCache<ISchedule>>({});
    private _paginationCount = new Map<string, number>();
    private _activeCacheKey = new BehaviorSubject<string>('');
    private _search = new BehaviorSubject<string>('');
    private default_page_size = 4;

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    get currentSchedules$(): Observable<ISchedule[]> {
        return this._entities.asObservable();
    }

    get schedulesSearch$(): Observable<string> {
        return this._search.asObservable();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Constructor
    // -----------------------------------------------------------------------------------------------------
    constructor(
        httpClient: HttpClient,
    ) {
        super(httpClient);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public Methods
    // -----------------------------------------------------------------------------------------------------

    protected override isCacheValid(cacheKey: string, page?: number): boolean {
        if(!page) return false;
        
        const entities = this._allEntities2.getValue();
        if (!entities[cacheKey]) return false;

        const data = entities[cacheKey];
        if(!data[page]) return false;

        if(this._paginationCount.get(cacheKey) === undefined) return false;
    
        const currentTime = Date.now();
        const cacheAge = currentTime - data[page].timestamp;
        return cacheAge < this.cacheConfig.cacheDuration;
          
    }

    override getList(parentId: string, queryParams?: Record<string, string>, forceRefresh?: boolean): Observable<ISchedule[]> {
        // Create a cacheKey that contain the parentId and paginationMetaData as a unique key to store the data in cache
        const cacheKey = this.generateCacheKey(parentId, queryParams);

        // Save the query params value of page and page size
        const page = queryParams ? Number(queryParams['page']) : 1;
        const page_size = queryParams ? Number(queryParams['limit']) : this.default_page_size;
        const search = queryParams ? queryParams['search'] : '';

        // Save all of the schedule and pagination data.
        const allEntities = this._allEntities2.getValue();
        const activeCacheKey = this._activeCacheKey.getValue();
        
        // Check if we don't need to refresh the data and the data we need exist and the data is eligible
        if (!forceRefresh && this.isCacheValid(cacheKey, page)) {
            let total_records = this._paginationCount.get(cacheKey);
            if(total_records){
                let total_pages = Math.ceil(total_records / page_size);
                let current_page = page;
                let pagination: PaginationMetaData = {
                    total_records,
                    page_size,
                    current_page,
                    total_pages,
                    has_next_page: current_page < total_pages,
                    has_previous_page: current_page > 1,
                }
                this._pagination.next(pagination);
            }
            let data = allEntities[cacheKey][page].data;
            this._entities.next(data);
            this._activeCacheKey.next(cacheKey);
            this._search.next(search);
            return of(data);
        }

        // Start the loading
        this._loading.next(true);

        // If the cachekey change through accessing different page or adding filter, clear all invalid cache
        if(cacheKey !== activeCacheKey){
            this.clearRelatedCache('', 'clearInvalid');
        }

        // Get the endpoint data from the endpoint variable and connect it with the endpoint plus the query string
        const endpoint = this.endpoints.list ? this.endpoints.list(parentId) : '';
        const url = `${this.baseUrl}${endpoint}${this.buildQueryString(queryParams)}`;

        // Call the Http request to get the schedule data
        return this.httpClient.get<{ message: { data: ISchedule[], pagination: PaginationMetaData } }>(url).pipe(
            map(res => {
                // Destruct the data and pagination from the response's message
                const { data, pagination } = res.message;

                // Pass the pagination to _paginationMetaData and return the data
                this._pagination.next(pagination);
                this._paginationCount.set(cacheKey, pagination.total_records);
                return data;
            }),
            tap(data => {
                // Add additional property to the data based on the parentId we passed before
                const enrichedData = data.map(schedule => ({
                    ...schedule,
                    [this.parentIdField]: schedule[this.parentIdField] || parentId
                }));

                // Get all of the schedule data and add the data we got plus the timestamp when the data got added
                const allEntities = this._allEntities2.getValue();

                //If there is not cache with the current cachekey, create the cache first
                if(!allEntities[cacheKey]){
                    allEntities[cacheKey] = {};
                }
                allEntities[cacheKey][page] = {
                    data: enrichedData,
                    timestamp: Date.now()
                }

                // Update all of the schedule cache to the updated data and set the loading to false
                this._entities.next(enrichedData);
                this._allEntities2.next(allEntities);
                this._activeCacheKey.next(cacheKey);
                this._search.next(search);
                this._loading.next(false);
            }),
            catchError(error => {
                console.error("Error occurred: ", error);
                this._loading.next(false);
                return this.handleOperationError('getList', error);
            }),
        )
    }

    /**
     * Get the schedule data
     * @param deviceId 
     * @param group_id optional, to find specific data with the selected group_id
     * @param search optional, to find the spesific data with the selected keyword
     * @param page optional, to access the data in certain pages. Default value will be 1
     * @param limit optional, to limit how many the data we will get. Default value will be the default_page_size
     * @param forceRefresh optional, to force the method to get the newest data from API despite the eligibility of the cache
     * @returns 
     */
    getCurrentSchedules(deviceId: string, search?: string, page?: number, limit?: number, sort?: string, sortBy?: string, forceRefresh = false): Observable<ISchedule[]> {
        const queryParams: Record<string, string> = {
            search: search ?? '',
            page: page?.toString() ?? '1',
            limit: limit?.toString() ?? this.default_page_size.toString(),
            sort: sort ?? 'desc',
            sortBy: sortBy ?? 'created_at',
        };

        return this.getList(deviceId, queryParams, forceRefresh);
    }

    override create(body: Partial<ISchedule>, parentId: string, queryParams?: Record<string, string>): Observable<ISchedule>;
    override create(body: Partial<ISchedule>[], parentId: string, queryParams?: Record<string, string>): Observable<ISchedule[]>;
    override create(
        body: Partial<ISchedule> | Partial<ISchedule>[],
        parentId: string,
        queryParams?: Record<string, string>
    ): Observable<ISchedule | ISchedule[]> {
        if (Array.isArray(body)) {
            throw new Error('Creating multiple schedules is not supported in DeviceScheduleService.');
        }
        // Start the loading
        this._loading.next(true);

        // Create the cacheKey and the url for API and the options
        // const cacheKey = this.generateCacheKey(parentId, queryParams);
        const cacheKey = this._activeCacheKey.getValue();
        const endpoint = this.endpoints.create ? this.endpoints.create(parentId) : '';
        const url = `${this.baseUrl}${endpoint}${this.buildQueryString(queryParams)}`;

        // Ensure parent ID is included in the request body
        const enrichedBody = {
            ...body,
            [this.parentIdField]: parentId
        }

        return this.httpClient.post<{ message: ISchedule }>(url, enrichedBody).pipe(
            map(response => response.message),
            tap(response => {
                // Deleting all schedule and pagination cache with the related parentId keyword other than the main one
                this.clearRelatedCache(cacheKey, 'create');

                this.managePagination(cacheKey, 'create');

                // Finish the loading
                this._loading.next(false);
            }),
            catchError(error => {
                console.error("Error occurred: ", error);
                this._loading.next(false);
                return this.handleOperationError('create', error);
            })
        );
    }
    
    /**
     * Schedule Methods
     * @param body 
     * @param groupId 
     * @param deviceId 
     * @returns 
     */
    createDeviceSchedule(body: ScheduleForm, groupId: string, deviceId: string): Observable<ISchedule> {
        const queryParams: Record<string, string> = { group_id: groupId };

        return this.create(body, deviceId, queryParams);
    }

    override update(id: string, body: Partial<ISchedule>, parentId: string, queryParams?: Record<string, string>, transformFn?: EntityTransformFn<ISchedule> | undefined): Observable<ISchedule> {
        this._loading.next(true);
        const cacheKey = this._activeCacheKey.getValue();
        const endpoint = this.endpoints.update ? this.endpoints.update(id, parentId) : '';
        const url = `${this.baseUrl}${endpoint}${this.buildQueryString(queryParams)}`;
        const page = this._pagination.getValue()?.current_page;

        const enrichedBody = {
            ...body,
            [this.parentIdField]: parentId
        };

        return this.httpClient.put<{message: ISchedule}>(url, enrichedBody).pipe(
            map(response => response.message),
            tap(response => {
                this.clearRelatedCache(cacheKey, 'update');

                const allEntities = this._allEntities2.getValue();

                if(allEntities[cacheKey] && page){
                    const entityIndex = allEntities[cacheKey][page].data.findIndex(entity => entity.id === id);
                    if(entityIndex !== -1){
                        const updatedEntity = transformFn 
                        ? transformFn(allEntities[cacheKey][page].data[entityIndex], response)
                        : response;

                        allEntities[cacheKey][page].data[entityIndex] = updatedEntity;
                        this._entities.next(allEntities[cacheKey][page].data);
                        this._allEntities2.next(allEntities);
                    }
                }
                this._loading.next(false);
            })
        )
    }

    /**
     * Schedule Methods
     * @param body 
     * @param groupId 
     * @param deviceId 
     * @returns 
     */
    updateDeviceSchedule(body: ScheduleForm, groupId: string, deviceId: string, scheduleId: string): Observable<ISchedule> {
        const queryParams: Record<string, string> = { group_id: groupId };

        return this.update(scheduleId, body, deviceId, queryParams);
    }

    override delete(id: string, parentId: string, body?: any, queryParams?: Record<string, string>): Observable<ISchedule> {
        // Start the loading
        this._loading.next(true);

        // Create the cacheKey and the url for API and the options
        const page = this._pagination.getValue()?.current_page;
        const cacheKey = this._activeCacheKey.getValue();
        const endpoint = this.endpoints.delete ? this.endpoints.delete(id, parentId) : '';
        const url = `${this.baseUrl}${endpoint}${this.buildQueryString(queryParams)}`;
        const options = body ? { body } : undefined;

        return this.httpClient.delete<{ message: ISchedule }>(url, options).pipe(
            map(response => response.message),
            tap(() => {
                // Deleting all schedule and pagination cache with the related parentId keyword other than the main one
                this.clearRelatedCache(cacheKey, 'delete');

                // Acquire the cache data
                // const allEntities = this._allEntities2.getValue();
                // if(page){
                //     const updatedData = allEntities[cacheKey][page].data.filter(item => item.id !== id);
                //     this.updateCache(cacheKey, updatedData, allEntities[cacheKey][page].timestamp, page);
                // }

                // this.managePaginationAfterDelete(parentId);
                this.managePagination(cacheKey, 'delete');

                // Finish the loading
                this._loading.next(false);
            }),
            catchError(error => {
                console.error("Error occurred: ", error)
                this._loading.next(false);
                return this.handleOperationError('delete', error);
            })
        );
    }

    /**
     * Delete a device-schedule
     * @param groupId 
     * @param deviceId 
     * @param scheduleId 
     * @returns 
     */
    deleteDeviceSchedule(groupId: string, deviceId: string, scheduleId: string): Observable<any> {
        const queryParams: Record<string, string> = { group_id: groupId };

        return this.delete(scheduleId, deviceId, undefined, queryParams);
    }

    /**
     * To decipher the cache key into the parentId and the params
     * @param cacheKey 
     * @returns 
     */
    private decipherCacheKey(cacheKey: string): {parentId: string, params: Record<string, string>} {
        let splitKey = cacheKey.split('/');
        let parentId = splitKey.shift() as string;
        let params = splitKey.length > 0 ? JSON.parse(splitKey.join('')) : {};
        return {parentId, params};
    }

    private managePagination(cacheKey: string, method: 'create' | 'delete'){
        let {parentId, params} = this.decipherCacheKey(cacheKey);
        let pagination = this._pagination.getValue();
        
        if(method === 'delete' && pagination){
            let search = params['search'];
            let sort = params['sort'];
            let sortby = params['sortby'];
            
            pagination.total_records--;
            pagination.total_pages = Math.ceil(pagination.total_records / pagination.page_size);
            let entities = this._entities.getValue();
            if(entities.length === 1){
                pagination.current_page--;
            };
            pagination.has_previous_page = pagination.current_page > 1;
            pagination.has_next_page = pagination.current_page < pagination.total_pages;

            this._pagination.next(pagination);
            this._paginationCount.set(cacheKey, pagination.total_records);
            return this.getCurrentSchedules(parentId, search, pagination.current_page, pagination.page_size, sort, sortby).pipe(take(1)).subscribe();
        }

        return this.getCurrentSchedules(parentId, undefined, 1, this.default_page_size, undefined, undefined, true).pipe(take(1)).subscribe();
    }

    /**
     * Create a unique string that contain the parentId data and the params search data
     * @param parentId 
     * @param params 
     * @returns 
     */
    protected generateCacheKey(parentId: string, params?: Record<string, string>): string {
        let paginationKey = parentId;

        if(params){
            let queryParams = {...params};
            delete queryParams['page'];
            let strParams = JSON.stringify(queryParams);
            paginationKey += `/${strParams}`;
        }

        return paginationKey;
    }

    /**
     * Merge two array of objects that won't have any duplicate
     * @param array1 
     * @param array2 
     * @param keyword 
     * @returns 
     */
    mergeArrayOfObject(array1: ISchedule[], array2: ISchedule[], keyword: keyof ISchedule): ISchedule[] {
        return [...new Map([...array1, ...array2].map(item => [item[keyword], item])).values()];
    }

    /**
     * Check if the number of items required for a page is exist or not
     * @param cacheLenght 
     * @param page 
     * @param page_size 
     * @returns 
     */
    isAmountOfCacheItemsExist(page: number, page_size: number, cacheKey: string): boolean {
        if(this._allEntities2.getValue()[cacheKey]){
            const cacheLength = this._allEntities2.getValue()[cacheKey][page].data.length;
            const total_records = this._paginationCount.get(cacheKey);
            return cacheLength >= page * page_size || cacheLength === total_records;
        }
        return false;
    }

    /**
     * Slice the cache items based on the value of page and page size
     * @param cache 
     * @param page 
     * @param page_size 
     * @returns 
     */
    sliceCacheItems(cache: ISchedule[], page: number, page_size: number) {
        const start = page * page_size - page_size;
        const stop = page * page_size;
        return cache.slice(start, stop);
    }

    /**
     * Clear the related cache of parentId other than the main one
     * @param parentId 
     * @param cacheKey 
     */
    private clearRelatedCache(cacheKey: string, option: 'clearInvalid' | 'delete' | 'create' | 'update') {
        const parentId = cacheKey.split('/')[0];
        const allEntities = this._allEntities2.getValue();
        const paginationCount = this._paginationCount;

        // Clear all cache with related parentId after create including the current cache
        if(option == 'create'){
            for(let key in allEntities){
                if(key.startsWith(parentId)){
                    delete allEntities[key];
                    paginationCount.delete(key);
                }
            }
        }

        // For delete, clear all related cache except itself and then clear all the pages except for the pages lower the current page
        if(option === 'delete'){
            const current_page = this._pagination.getValue()?.current_page ?? 1;

            for(let key in allEntities){
                if(key.startsWith(parentId) && key !== cacheKey){
                    delete allEntities[key];
                    paginationCount.delete(key);
                }
            }

            for(let page in allEntities[cacheKey]){
                if(Number(page) >= current_page){
                    delete allEntities[cacheKey][page];
                }
            }
            

        }

        // Clear all cache with related parentId after update except for the current cache
        if(option === 'update'){
            for(let key in allEntities){
                if(key.startsWith(parentId) && key !== cacheKey){
                    delete allEntities[key];
                    paginationCount.delete(key);
                }
            }
        }

        // Clear all invalid page from all cache
        if(option === 'clearInvalid'){
            for(let key in allEntities){
                for(let page in allEntities[key]){
                    if(!this.isCacheValid(key, Number(page))){
                        delete allEntities[key][page];
                    }
                }
            }
        }

        // Update the schedule and pagination cache
        this._allEntities2.next(allEntities);
    }

    /**
     * Update the entity cache
     * @param cacheKey 
     * @param data 
     * @param timestamp 
     */
    private updateCache(cacheKey: string, data: ISchedule[], timestamp: number, page: number) {
        const allEntities = this._allEntities2.getValue();
        allEntities[cacheKey][page] = { data, timestamp };
        this._allEntities2.next(allEntities);
    }

}
