import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Logger } from '@gci/helpers/logger';
import { PaginatedResponse } from './base.type';

interface SimpleResponse<T> {
  message: T[];
}

type ApiResponse<T> = PaginatedResponse<T> | SimpleResponse<T>;

export class ServiceError extends Error {
  constructor(
    message: string,
    public operation: string,
    public endpoint: string,
    public originalError: any
  ) {
    super(message);
    this.name = 'ServiceError';
    Error.captureStackTrace(this, ServiceError);
  }
}

export interface BaseEntityFilter {
  [key: string]: any;
}

export interface BaseEntity {
  id: string;
  [key: string]: any;
}

export interface EntityCache<T> {
  [key: string]: {
    data: T[];
    timestamp: number;
  };
}

export interface CacheConfig {
  cacheDuration: number;
}

export interface ParentChildCrudEndpoints {
  list?: (parentId: string) => string;
  create?: (parentId: string) => string;
  search?: () => string;
  update?: (id: string, parentId?: string) => string;
  delete?: (id: string, parentId?: string) => string;
  [key: string]: any;
}

export type EntityTransformFn<T> = (entity: T, response: T) => T;

@Injectable()
export abstract class BaseParentChildEntityService<
  T extends BaseEntity, 
  F extends BaseEntityFilter = BaseEntityFilter,
  P extends string = string // Type for parent ID, defaults to string
> {

  protected _pagination = new BehaviorSubject<{
    total_records: number;
    page_size: number;
    current_page: number;
    total_pages: number;
    has_next_page: boolean;
    has_previous_page: boolean;
  } | null>(null);
  
  protected baseUrl = environment.apiBaseUrl;
  protected abstract endpoints: ParentChildCrudEndpoints;
  protected abstract parentIdField: keyof T; // Field name in entity T that references parent ID

  protected cacheConfig: CacheConfig = {
    cacheDuration: 5 * 60 * 1000
  };

  protected _allEntities: BehaviorSubject<EntityCache<T>> = new BehaviorSubject<EntityCache<T>>({});
  protected _entities: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  protected _filter: BehaviorSubject<F> = new BehaviorSubject<F>({} as F);
  protected _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected _logger!: Logger;

  constructor(protected httpClient: HttpClient) {    
    this._logger = new Logger(this.constructor.name);
  }

  public pagination$ = this._pagination.asObservable();

  setCacheConfig(config: Partial<CacheConfig>) {
    this.cacheConfig = { ...this.cacheConfig, ...config };
  }

  set filter(filter: F) {
    this._filter.next(filter);
  }

  get filter$(): Observable<F> {
    return this._filter.asObservable();
  }

  get entities$(): Observable<T[]> {
    return this._entities.asObservable();
  }

  get loading$(): Observable<boolean> {
    return this._loading.asObservable();
  }

  protected isCacheValid(parentId: P): boolean {
    const entities = this._allEntities.getValue();
    if (!entities[parentId]) return false;

    const currentTime = Date.now();
    const cacheAge = currentTime - entities[parentId].timestamp;
    return cacheAge < this.cacheConfig.cacheDuration;
  }

  getList(parentId: P, queryParams?: Record<string, string>, forceRefresh = false): Observable<T[]> {
    const entities = this._allEntities.getValue();
    
    if (!forceRefresh && this.isCacheValid(parentId) && entities[parentId]) {
      this._entities.next(entities[parentId].data);
      return of(entities[parentId].data);
    }

    this._loading.next(true);
    const endpoint = this.endpoints.list ? this.endpoints.list(parentId) : '';
    const url = `${this.baseUrl}${endpoint}${this.buildQueryString(queryParams)}`;
    
    return this.httpClient.get<ApiResponse<T>>(url).pipe(
      map(response => {
        // Check if response is the paginated format
      if (response?.message && !Array.isArray(response.message)) {
        if (!response.message.data || !Array.isArray(response.message.data)) {
          throw new Error(`Invalid paginated response format for ${url}`);
        }
        // Store pagination info if needed
        if (response.message.pagination) {
          this._pagination?.next(response.message.pagination);
        }
        return response.message.data;
      }
      
      // Handle simple array response
      if (!Array.isArray(response?.message)) {
        throw new Error(`Invalid response format for ${url}`);
      }
      
      return response.message;
      }),
      tap(response => {
         // Add type guard to ensure response is an array
        if (!Array.isArray(response)) {
          throw new Error('Response message is not an array ' +  url);
        }

        // Add parent ID to each entity if not present
        const enrichedResponse = response.map(entity => ({
          ...entity,
          [this.parentIdField]: entity[this.parentIdField] || parentId
        }));

        this._entities.next(enrichedResponse);
        const allEntities = this._allEntities.getValue();
        allEntities[parentId] = {
          data: enrichedResponse,
          timestamp: Date.now()
        };
        this._allEntities.next(allEntities);
        this._loading.next(false);
      }),
      catchError(error => {
        this._loading.next(false);        
        return this.handleOperationError('getList', error);
      })
    );
  }

  /*
    * Create a single entity
    * @param body Single entity to create
    * @param parentId Parent ID
    * @param queryParams Optional query parameters
    */
  create(body: Partial<T>, parentId: P, queryParams?: Record<string, string>): Observable<T>;
  /**
    * Create multiple entities
    * @param body Array of entities to create
    * @param parentId Parent ID
    * @param queryParams Optional query parameters
    */
  create(body: Partial<T>[], parentId: P, queryParams?: Record<string, string>): Observable<T[]>;
  create(
    body: Partial<T> | Partial<T>[],
    parentId: P,
    queryParams?: Record<string, string>
  ): Observable<T | T[]> {
    this._loading.next(true);
    const endpoint = this.endpoints.create ? this.endpoints.create(parentId) : '';
    const url = `${this.baseUrl}${endpoint}${this.buildQueryString(queryParams)}`;

   // Ensure parent ID is included in the request body
    const enrichedBody = Array.isArray(body)
      ? body.map(item => ({
          ...item,
          [this.parentIdField]: parentId
        }))
      : {
          ...body,
          [this.parentIdField]: parentId
      };

    return this.httpClient.post<{message: T | T[]}>(url, enrichedBody).pipe(
      map(response => response.message),
      tap(response => {
        const allEntities = this._allEntities.getValue();
        let newEntities = Array.isArray(response) ? response : [response];
        
        if (allEntities[parentId]) {
          allEntities[parentId] = {
            data: [...newEntities, ...allEntities[parentId].data],
            timestamp: allEntities[parentId].timestamp
          };
        } else {
          allEntities[parentId] = {
            data: newEntities,
            timestamp: Date.now()
          };
        }
        
        this._entities.next(allEntities[parentId].data);
        this._allEntities.next(allEntities);
        this._loading.next(false);
      }),
      catchError(error => {
        this._loading.next(false);
        return this.handleOperationError('create', error);
      })
    );
  }

  update(
    id: string, 
    body: Partial<T>, 
    parentId: P,
    queryParams?: Record<string, string>,
    transformFn?: EntityTransformFn<T>
  ): Observable<T> {
    this._loading.next(true);
    const endpoint = this.endpoints.update ? this.endpoints.update(id, parentId) : '';
    const url = `${this.baseUrl}${endpoint}${this.buildQueryString(queryParams)}`;

    // Ensure parent ID is included in the request body
    const enrichedBody = {
      ...body,
      [this.parentIdField]: parentId
    };

    return this.httpClient.put<{message: {data: T}}>(url, enrichedBody).pipe(
      map(response => response.message.data),
      tap(response => {
        const allEntities = this._allEntities.getValue();
        if (allEntities[parentId]) {
          const entityIndex = allEntities[parentId].data.findIndex(e => e.id === id);
          if (entityIndex !== -1) {
            const updatedEntity = transformFn 
              ? transformFn(allEntities[parentId].data[entityIndex], response)
              : response;

            allEntities[parentId].data[entityIndex] = updatedEntity;
            this._entities.next(allEntities[parentId].data);
            this._allEntities.next(allEntities);
          }
        }
        this._loading.next(false);
      }),
      catchError(error => {
        this._loading.next(false);
        return this.handleOperationError('update', error);
      })
    );
  }

  delete(id: string, parentId: P, body?: any, queryParams?: Record<string, string>): Observable<T> {
    this._loading.next(true);
    const endpoint = this.endpoints.delete ? this.endpoints.delete(id, parentId) : '';
    const url = `${this.baseUrl}${endpoint}${this.buildQueryString(queryParams)}`;
    const options = body ? { body } : undefined;

    return this.httpClient.delete<{message: T}>(url, options).pipe(
      map(response => response.message),
      tap(response => {
        const allEntities = this._allEntities.getValue();
        if (allEntities[parentId]) {
          allEntities[parentId] = {
            data: allEntities[parentId].data.filter(item => item.id !== id),
            timestamp: allEntities[parentId].timestamp
          };
          this._entities.next(allEntities[parentId].data);
          this._allEntities.next(allEntities);
        }
        this._loading.next(false);
      }),
      catchError(error => {
        this._loading.next(false);
        return this.handleOperationError('delete', error);
      })
    );
  }

  clearCache(): void {
    this._allEntities.next({});
    this._entities.next([]);
  }

  protected handleOperationError(operation: string, error: HttpErrorResponse) {
    const errorMessage = error.error?.message || error.message || 'Unknown error occurred';
    console.error(`${operation} failed:`, {
      operation,
      endpoint: this.endpoints,
      error: error.error,
      status: error.status,
      statusText: error.statusText
    });
    
    return throwError(() => new ServiceError(
      errorMessage,
      operation,
      JSON.stringify(this.endpoints),
      error
    ));
  }

  protected buildQueryString(params?: Record<string, string | undefined>): string {
    if (!params) return '';
    const queryParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (value) queryParams.append(key, value);
    });
    return queryParams.toString() ? `?${queryParams.toString()}` : '';
  }

  refreshCache(parentId: P, queryParams?: Record<string, string>): Observable<T[]> {
    return this.getList(parentId, queryParams, true);
  }
}