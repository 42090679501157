import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseEntityFilter, BaseParentChildEntityService, ParentChildCrudEndpoints } from '@gci/components/base-page/base-parent-child.service';
import { IDeviceObject, IDeviceObjectRequest } from 'app/models/devices.type';
import { catchError, map, Observable, tap, throwError } from 'rxjs';

interface DeviceObjectFilter extends BaseEntityFilter {
    // Add member-specific filter properties if needed
}

@Injectable({
    providedIn: 'root'
})
export class DeviceObjectService extends BaseParentChildEntityService<IDeviceObject, DeviceObjectFilter, string> {
    protected override parentIdField: keyof IDeviceObject = 'device_id';

    // -----------------------------------------------------------------------------------------------------
    // @ Properties
    // -----------------------------------------------------------------------------------------------------

    protected override endpoints: ParentChildCrudEndpoints = {
        list: (deviceId: string) => `device-service/${deviceId}/object`,
        create: (deviceId: string) => `device-service/${deviceId}/object`,
        update: (id: string, deviceId?: string) => `device-service/${deviceId}/object/${id}`,
        delete: (scheduleId: string, deviceId?: string) => `device-service/${deviceId}/object/${scheduleId}`
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    get currentDeviceObjects$(): Observable<IDeviceObject[]> {
        return this._entities.asObservable();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Constructor
    // -----------------------------------------------------------------------------------------------------
    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient)
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public Methods
    // -----------------------------------------------------------------------------------------------------

    setDeviceObject(deviceObjects: IDeviceObject[], groupId: string, deviceId: string) {
        this._entities.next(deviceObjects);

        const allEntities = this._allEntities.getValue();
        allEntities[deviceId] = {
            data: deviceObjects,
            timestamp: Date.now()
        };

        this._allEntities.next(allEntities);
    }

    getCurrentDeviceObjects(groupId: string, deviceId: string): Observable<IDeviceObject[]> {
        const queryParams: Record<string, string> = { group_id: groupId, limit: '100', sortby: 'meta_data_config', sort: 'desc' };
        return this.getList(deviceId, queryParams);
    }

    /**
     * Create a device-object
     * @param body 
     * @returns 
     */
    createDeviceObject(body: IDeviceObjectRequest[], groupId: string, deviceId: string): Observable<IDeviceObject[]> {
        const queryParams: Record<string, string> = { group_id: groupId };
        return this.create(body, deviceId, queryParams);
    }

    /**
     * Create a device-object
     * @param body 
     * @returns 
     */
    updateDeviceObject(body: IDeviceObjectRequest, groupId: string, deviceId: string, id: string): Observable<IDeviceObject> {
        const queryParams: Record<string, string> = { group_id: groupId };
        return this.update(id, body, deviceId,  queryParams);
    }

    /**
     * Delete a device object
     * @param id 
     * @param groupId 
     * @param deviceId 
     * @returns 
     */
    deleteDeviceObject(id: string, groupId: string, deviceId: string): Observable<IDeviceObject> {
        const queryParams: Record<string, string> = { group_id: groupId };
        return this.delete(id, deviceId, undefined, queryParams);
    }

}
